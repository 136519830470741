import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isDevMode, LOCALE_ID, Provider } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { EnvironmentConfig, Language } from '@bs/models/common/environment-config';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { CustomPageTitleStrategy } from './helpers/custom-page-title.strategy';
import { LocalStorage } from '@bs/universal/universal.providers';
import { WINDOW } from '@bs/universal/window.service';
import { LangChangeEvent, MissingTranslationHandler, MissingTranslationHandlerParams, provideTranslateService, TranslateLoader, TranslateModuleConfig, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';


const optionalProviders: Array<Provider> = [];

/**
 * handles the missing translations
 */
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  /**
   * the missingTranslations variable array
   */
  private missingTranslations: string[] = [];

  /**
   * A function that handles missing translations.
   */
  handle(params: MissingTranslationHandlerParams) {

    if (this.missingTranslations.indexOf(params.key) > -1) {
      return params.key;
    }
    this.missingTranslations.push(params.key);
    // console.log(params.key);
    console.log(`Translation missing: '${params.key}'`);
    return params.key;
  }

}

/**
 * service purpose is to fetch the translation
 */
export class TranslationLoaderService implements TranslateLoader {
  /**
   * the menu url from environment
   */
  readonly url = this.config.api.cms;
  /**
   * the webappid environment
   */
  readonly webAppId = this.config.webAppId;

  /**
   * The constructor
   */
  constructor(private client: HttpClient, private settings: AppSettingsService, private config: EnvironmentConfig) {
  }

  /**
   * function returns us the translations from the api
   */
  getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders({
      'Accept-Language': lang || this.config.defaultLangCode,
      BookmakerId: `${this.config.bookmakerId}`
    });
    this.settings.saveSetting('languageCode', lang);
    return this.client.get(`${this.url}/apps/${this.webAppId}/translations`, {headers});
  }
}

function getLocale(config: EnvironmentConfig): string {

  const languages = config.langs.map(l => l.code);
  const match = window.navigator.language.split('-')[0];

  if (languages.includes(match)) {
    return window.navigator.language;
  }

  return 'en';
}

const LanguageProvider = (localStorage: LocalStorage, translate: TranslateService, config: EnvironmentConfig, window: Window, document: Document) => {
  translate.onLangChange.subscribe({
    next: (data: LangChangeEvent) => localStorage.setItem('lang', data.lang)
  });

  const langs = config.langs.map(l => l.code);
  translate.addLangs(langs);

  const lang = [
    window?.location?.pathname.split('/')[1],
    localStorage.getItem('lang'),
    translate.getBrowserLang(),
    config.defaultLangCode
  ].find(l => langs.includes(l));

  document.documentElement.lang = lang;
  translate.setDefaultLang(lang);
  translate.currentLang = lang;
  localStorage.setItem('lang', lang);

  return lang;
};

export const translationProviders = () => {

  let optionalTranslateConfig: Partial<TranslateModuleConfig> = {};

  if (isDevMode()) {
    optionalTranslateConfig = {
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    };
  }

  return [
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoaderService,
        deps: [HttpClient, AppSettingsService, EnvironmentConfig]
      },
      ...optionalTranslateConfig
    }),
    ...optionalProviders,
    {
      provide: Language,
      useFactory: LanguageProvider,
      deps: [LocalStorage, TranslateService, EnvironmentConfig, WINDOW, DOCUMENT]
    },
    {
      provide: TitleStrategy,
      useClass: CustomPageTitleStrategy
    }
  ];
};
