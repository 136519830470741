import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { coreProviders } from '@bs/common/core.providers';
import { translationProviders } from '@bs/common/translation.providers';
import { universalProviders } from '@bs/universal/universal.providers';
import { appRoutes } from '@winchester/app/app-routes';
import { environment } from '@winchester/environments/environment';
import { DialogService } from 'primeng/dynamicdialog';

export const appConfig: ApplicationConfig = {
  providers: [
    translationProviders(),
    DialogService,
    coreProviders(environment),
    provideRouter(appRoutes,
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    universalProviders(),
    provideAnimations()
  ]
};
