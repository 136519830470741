import { Component, Inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Language } from '@bs/models/common/environment-config';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { TranslatePipe } from '@ngx-translate/core';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [MessageService, ConfirmationService],
  standalone: true,
  imports: [ToastModule, RouterOutlet, ConfirmDialogModule, TranslatePipe]
})
export class AppComponent {
  constructor(appSetting: AppSettingsService,
              @Inject(Language) public lang: string, router: Router) {
    // authService.isLogged();
    appSetting.globalErrors$.subscribe({
      next: errors => {
        // this.messageService.add({severity: 'error', summary: 'Warning', detail: `${errors.key}:${errors.value}`});
        router.navigate([`${this.lang}/error`]);
      }
    });
  }

}
